.Node {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 1px gray;
  background-color: #fff;
  background: #fff;
  &_Header {
    border-radius: 10px 10px 0 0;
    padding: 0 13px;
    font-weight: 600;
    color: #fff;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    &_Title {
      margin: 0 0 0 5px;
      font-size: 13px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &_Index {
        color: #e7e8e7;
      }
    }
    &_Class {
      margin-right: 13px;
      margin-left: 8px;
      background: #fff;
      border-radius: 8px;
      font-size: 10px;
      font-weight: bold;
      padding: 2px 6px 0 6px;
      text-transform: uppercase;
    }
  }
  &_Connections {
    margin: 0;
    padding: 0;
    position: relative;
    &_Condition {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      list-style-type: none;
      margin-bottom: 0;
      border-radius: 4px;
      background-color: #f0f1f0;
      padding: 4px;
      cursor: pointer;
      width: auto;
      white-space: nowrap;
      &_PlusIcon {
        font-size: 19px;
      }
      &_Add {
        font-style: italic;
        color: #a2a2a2;
        position: relative;
      }
      &_Text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &_NewNodeDropdown {
    position: absolute !important;
    &_Trigger {
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }
}
