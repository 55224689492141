.MenuReportsComponent {
  // background: #FAFAFF;
  color: #737376;

  .submenu-header {
    display: flex;

    .title {
      color: #a3aed0;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.28px;
      text-transform: uppercase;
      margin: 21px 0 -3px 19px;
    }
  }

  .submenu-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #222;
    text-decoration: none;
    font-weight: 600;
    width: 90%;
    margin: 7px 0 0 19px;
    padding: 5px;
    cursor: pointer;

    .title {
      line-height: 30px;
      letter-spacing: -0.28px;
      font-size: 14px;
      color: #a3aed0;
      margin-right: auto;

      padding: 0px 0px 0px 10px;
    }

    .icon {
      display: flex;
    }

    .selected-bar {
      transform: translate(11px);
      width: 6px;
      height: 33px;
      flex-shrink: 0;
      border-radius: 25px 0 0 25px;
      background: var(--main-blue);
    }
  }

  .submenu-section.selected {
    color: #2b3674;
  }

  .submenu-section.selected .title {
    color: #2b3674 !important;
  }

  .submenu-section:hover {
    background-color: #f4f7fe;
    border-radius: 10px;
  }
}
