.InvitePopup {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(238, 239, 240, 0.831372549);
  justify-content: center;
  margin: -10px;
  z-index: 9;

  .invite-popup-container {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    width: 350px;
    height: 150px;
    background-color: white;

    .invite-popup-header {
      display: flex;
      margin: 15px 0px 5px 25px;
      width: 100%;

      font-family: Inter, sans-serif !important;
      font-style: normal;
      font-size: 15px !important;
      line-height: 15px !important;
      letter-spacing: -0.408px;
      color: black;
      font-weight: 700;
    }
    .invite-popup-email-input {
      margin: 15px 30px;
      border: none;

      .input-componenet {
        width: 100%;
      }

      .autocomplete-component {
        width: 100%;

        .text-field {
          width: 100%;
        }

        .MuiAutocomplete-popper {
          z-index: 999999;
        }
      }
    }
    .invite-popup-buttons {
      display: flex;
      justify-content: space-around;
      width: 100%;

      .add-style {
      }
      .cancel-style {
      }
    }
  }
}
