.SipConfigurationDialog_HeaderTitle {
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-right: 22px;
}

.SipConfigurationDialog_ParameterContainer {
  margin: 22px 0 22px 30px;
  width: 60%;
}

.SipConfigurationDialog_ContactUsHeader {
  color: #333;
  margin: 30px;
}
