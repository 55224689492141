.Conversation {
  height: 100%;
  .conversation-item {
    text-decoration: none;
    display: block;
    height: 100%;
    box-sizing: border-box;
    background-color: #fff;
    cursor: pointer;
  }
}
