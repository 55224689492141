.EmailServiceConfiguration {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;

  &_Recipients {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 60px;

    .Title {
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 14px;
    }
  }

  &_EmailInfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .Title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 14px;
    }
  }

  &_Subject {
    display: flex;
    flex-direction: column;

    .Title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 14px;
    }
  }

  &_Body {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    &_Title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 14px;
    }

    &_Input {
      height: 150px;
      width: 100%;
      border: none;
      background-color: #f6f6f6;
      font-size: 14px;
      overflow-y: auto;
      resize: none;
    }
  }
}
