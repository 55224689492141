.ConversationsInboxContainer {
  position: relative;
  width: 77%;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .ConversationInbox {
    position: absolute;
    left: 342px;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
  }
  @media all and (max-width: 1280px) {
    .ConversationInbox {
      //margin-left: 0px;
      left: 300px;
    }

    .no-access-text {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
    }
  }
  .ConversationInbox-mobile {
    left: 0;
    z-index: 900;
  }
}

.ConversationsInboxContainerisMobile {
  width: 100%;
  height: 100%;
}
