.EntityDialog {
  flex: 1;
  min-height: 0;

  &_Title {
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #56595f;
  }

  &_LabeledCheckboxContainer {
    margin-left: 14px;
  }

  select {
    margin-left: 22px;
  }

  &_UtterancesContainer {
    margin: 22px 0 22px 22px;
  }
}
