.MessagesMenu {
  display: flex;
  flex-direction: column;

  .submenu-file {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-weight: 600;
    width: 90%;
    margin-left: 19px;
    margin-right: 7px;
    padding: 5px;

    cursor: pointer;

    .file-container {
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        display: flex;
      }
    }
    .title {
      line-height: 30px;
      letter-spacing: -0.28px;
      font-size: 14px;
      color: #a3aed0;

      padding: 0px 0px 0px 10px;
    }

    .selected-bar {
      transform: translate(11px);
      width: 6px;
      height: 33px;
      flex-shrink: 0;
      border-radius: 25px 0 0 25px;
      background: var(--main-blue);
    }
  }

  .submenu-file:hover {
    background-color: #f4f7fe;
    border-radius: 10px;
  }

  .submenu-file.selected {
    border-radius: 5px;
    color: #2b3674;
  }

  .submenu-file.selected .title {
    // color: #ffffff;
    color: #2b3674;
  }

  .submenu-file.selected .total-container .unread {
    color: #a3aed0;
  }
  .total-container {
    .total-collector {
      display: flex;
      flex-direction: row;

      .unread {
        color: #a3aed0;
        text-align: right;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: -0.408px;
      }
      .total {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.408px;
        color: #a3aed0;
        margin-left: 3px;
      }
    }
  }
}
