.ConversationSubject {
  display: flex;
  margin: 0 0 5px 5px;
  white-space: nowrap;
  flex-direction: column;
  align-items: flex-end;

  .form {
    width: 100%;
    padding: 0%;
    align-items: flex-start;
    .subject-input {
      margin: 0 5px 0 5px;
      width: 95%;
      border: 1px solid rgba(0, 0, 0, 0);

      .MuiFilledInput-root {
        background-color: white;
      }
      .MuiFilledInput-input {
        padding: 0px 0px 0px 0px;
      }
      .Mui-disabled {
        color: black;
      }
    }
  }
  .save-button,
  .edit-button {
    margin-right: 10px;
    align-items: flex-end;
    border-width: 0px;
    box-sizing: border-box;
    // display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 600;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: rgba(9, 30, 66, 0.04);
    border-radius: 3px;
    height: 1.5em;
    line-height: 1.5em;
    padding: 0px 8px;
    transition: background 0.1s ease-out 0s,
      box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    width: auto;
    outline: none !important;
    color: rgb(66, 82, 110) !important;

    cursor: pointer;
  }
  .edit-button:hover,
  .save-button:hover {
    background: hsla(218, 76%, 15%, 0.1);
  }
}
