.tabList {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin: 5px;
  background-color: rgb(233, 233, 233);
  border-radius: 10px;
  height: 41px;
}

.tabListItem {
  flex-grow: 1;
  flex-basis: 0;
  list-style: none;
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
  margin-top: 3px;
}

.tabListActive {
  background-color: white;
  border-radius: 8px;
  font-weight: 600;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  height: 35px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
  margin-top: 3px;
}

.tabs {
  display: flex;
  flex-direction: column;
  height: calc(100% - 62px);
}

.tabContent {
  flex: 1;
  min-height: 0;
  padding: 30px;
}
