$play-size: 6px;

.AudioFileMessage {
  display: flex;
  align-items: center;
  &_Play {
    outline: none;
    cursor: pointer;
    display: inline-block;
    width: 0;
    height: 0;
    border: none;
    background: transparent;
    border-style: solid;
    border-width: $play-size 0 $play-size 2 * $play-size;
    border-color: transparent transparent transparent #fff;
  }
}
