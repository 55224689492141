.Auth {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  background-color: white;
  overflow-y: auto;
  align-items: center;

  @media screen and (min-width: 1300px), screen and (min-height: 839px) {
    flex-flow: row nowrap;
    justify-content: center;
  }

  .Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 410px;
    height: 839px;
    margin-right: 231px;
    background-color: #fff;
    margin: auto;

    @media screen and (max-width: 500px) {
      margin: 0;
      width: 225px;
      max-height: 100%;
      border-radius: 0;
      padding-top: 40px;
      margin-right: 0;
      height: auto;
    }

    @media screen and (min-width: 1300px) and (min-height: 839px) {
      margin: 0;
      margin-right: 100px;
    }

    .Logo {
      flex-shrink: 0;
      margin-bottom: 75px;
      margin-top: 40px;

      @media screen and (min-width: 1300px) and (min-height: 839px) {
        margin-top: 10px;
      }

      @media screen and (max-width: 500px) {
        margin-top: 0;
        margin-bottom: 40px;
      }
    }

    .Title {
      margin-bottom: 10px;
      color: #2b3674;
      font-size: 36px;
      line-height: 56px;
      letter-spacing: -0.72px;
    }

    .Subtitle {
      margin-bottom: 36px;
      color: var(--secondary-grey-600, #a3aed0);
      font-size: 16px;
      letter-spacing: -0.32px;
    }

    .FormRow {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 100%;
      margin-top: 8px;
      margin-bottom: 8px;

      @media screen and (max-width: 500px) {
        flex-flow: column nowrap;
        align-items: flex-start;
      }
    }

    .FormLabel {
      color: #2b3674;
      font-size: 14px;
    }

    .FormLabelRequired {
      color: var(--main-blue);
      font-size: 14px;
    }

    .RegisterFormLabel {
      display: flex;
      flex-flow: row nowrap;
    }

    .Input {
      background-color: white;
      border-radius: 16px;
      border: 1px solid var(--secondary-grey-500, #e0e5f2);
      color: #2b3674;
      font-size: 14px;
      padding-top: 10px;
      padding-bottom: 12px;
      padding-left: 21px;
      margin-top: 11px;
      margin-bottom: 22px;
    }

    .RegisterInput {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: auto;
      width: 225px;
      @media screen and (max-width: 500px) {
        margin-top: 12px;
      }
    }

    .Input::placeholder {
      color: var(--secondary-grey-600, #a3aed0);
    }

    .Dropdown {
      margin-left: auto;
      width: 225px;

      @media screen and (max-width: 500px) {
        margin-top: 12px;
      }
    }

    .SpanButton {
      align-self: flex-end;
      color: var(--main-blue);
      font-size: 14px;
      line-height: 20px;
      margin-top: 9px;
      margin-bottom: 25px;
      cursor: pointer;
    }

    .Error {
      align-self: center;
      color: red;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .Submit {
      margin-top: 8px;
      background-color: var(--main-blue);
      border-radius: 16px;
      width: 100%;
      padding: 25px 8px;
      border: 0;
      flex: 0 0 auto;
      align-self: center;
      cursor: pointer;
      color: #fff;
      text-align: center;
      font-size: 14px;
      @media screen and (max-width: 500px) {
        align-self: flex-start;
        width: 225px;
      }
    }

    .CreateAccountContainer {
      margin-top: 24px;
      align-self: center;

      @media screen and (max-width: 500px) {
        align-self: flex-start;
      }
    }

    .NotRegistered {
      color: #2b3674;
      font-size: 14px;
      line-height: 26px;
      margin-right: 2px;
    }

    .Rights {
      align-self: center;
      color: var(--secondary-grey-600, #a3aed0);
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.28px;
      margin-top: 40px;

      @media screen and (min-width: 1300px) and (min-height: 839px) {
        margin-top: 192px;
      }
    }

    .RegisterRights {
      margin-top: 40px;
    }

    .Rights:after {
      content: '';
      display: block;
      height: 15px;
    }
  }

  .ShowOnlyOnDesktop {
    @media screen and (max-width: 1300px), screen and (max-height: 839px) {
      display: none;
    }
  }
}
