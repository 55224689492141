.ScrollToBottomButton {
  position: absolute;
  height: 40px;
  width: 40px;

  left: 50%;
  bottom: 13%;

  background: white;

  border-radius: 50%;
  border-style: solid;
  border-color: lightgray;

  transform: translate(-50%, 0);

  cursor: pointer;

  .arrow-down {
    position: relative;

    width: 20px;
    height: 20px;

    margin: 8px 8px;

    color: #728cd8;
  }
}

.ScrollToBottomButton:hover {
  background-color: #555;
}

.ScrollToBottomButton.hidden {
  display: none;
}
