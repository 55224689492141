.no-conversations {
  margin: 0 0 5px 5px;
  color: rgba(168, 175, 185, 0.68);

  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.408px;
}
