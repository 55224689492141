.DocumentFacebookFileMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &_Img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  a {
    display: flex;
    color: #000;
  }
  a:hover {
    color: #000;
  }
}
