.CarouselButton {
  border: solid 1px lightgray;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 0.9;
  cursor: pointer;
}

.CarouselButton:hover {
  opacity: 1;
}
