.ScriptDialog {
  flex: 1;
  min-height: 0;
  &_NameInput {
    flex-grow: 1;
    color: white;
    border: none;
    padding: 8px 20px 8px 20px;
    font-size: 14;
    height: 37;
    border-radius: 4;
  }
  &_Modal {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    cursor: default;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    &_Backdrop {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.65;
    }
  }
}
