.ChatAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;

  height: 25px !important;
  width: 25px !important;
  background-color: #bbb;
  border-radius: 50% !important;
}

.ChatAvatarLarge {
  height: 32px !important;
  width: 32px !important;
}

.ChatAvatar .sender-first-letter {
  font-size: 15px;
  letter-spacing: -0.408px;
  color: rgba(86, 89, 95, 0.4);
}

.ChatAvatar .sender-first-letter-large {
  font-size: 19px;
}

.NoSenderImg {
  height: 25px;
}

.NoSenderImgLarge {
  height: 32px;
}
