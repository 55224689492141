.OptionCheckbox {
  margin-right: 20px;
  display: flex;
  align-items: center;

  &_CheckboxTitle {
    font-weight: 600 !important;
    color: #56595f !important;
  }
}
