.CarouselEditorSlide {
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 8px;
  //box-shadow: -5px 0px 8px 0px #888888;
  width: 98%;
  margin: 10px 0px;

  &_Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0px;

    &_Navigation {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 40%;
      margin-left: 30%;

      &_ButtonColumn {
        width: 12%;

        &_Button {
          font-size: 24px !important;
          color: #676565;
          cursor: pointer;
          &:hover {
            color: black;
          }
        }
      }

      &_TitleColumn {
        width: 76%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &_Title {
          color: #676565;
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }

    &_DeleteIcon {
      cursor: pointer;
      color: #676565;
      &:hover {
        color: #d44141;
      }
    }
  }
}
