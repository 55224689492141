.SettingsMenu {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;

  .settings-menu {
    width: 230px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-right: 1px solid #eef3ff;

    .settings-header {
      margin: 10px 0 30px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #fff;
      box-sizing: border-box;
      padding: 0 0px 0 12px;
      color: #000;
      font-family: DM Sans;
      font-size: 19px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: -0.408px;
    }

    .settings-menu-lines-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 5px 0 10px;

      .settings-menu-lines {
        display: flex;
        flex-direction: row;
        margin: 5px 0 5px 5px;
        align-items: center;
        text-decoration: none;

        .icon.selected {
          .MuiSvgIcon-root {
            color: var(--main-blue) !important;
          }
        }
        .sub-section-name {
          padding: 0 0 0 15px;
          color: #a3aed0;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: -0.28px;
        }
      }

      .selected-bar {
        transform: translate(5px);
        width: 6px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 25px 0 0 25px;
        background: var(--main-blue);
      }
    }

    .settings-menu-lines-container:hover {
      display: flex;
      cursor: pointer;
      background-color: #f4f7fe;
      border-radius: 10px;
    }
  }
  .sub-menu-container {
    padding: 10px;
    overflow-y: auto;
    position: relative;
    width: 100%;
    height: 100%;
  }
}
