.headerTitle {
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-right: 22px;
}

.inputFieldsContainer {
  display: flex;
  flex-direction: column;

  input {
    width: 500px;
    margin: 20px 0 0 30px;
  }

  label {
    padding-right: 10px;
    width: 200px;
    color: #000;
    margin-left: 30px;
    margin-top: 30px;
  }
}
