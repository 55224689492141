.FlowSettingsDialog {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
  font-size: 17px;

  &_BackgroundCover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
  }

  &_DialogContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 200px;
    background-color: white;
    margin: 40px auto;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);

    .material-icons {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      font-weight: bold;
      font-size: 35px;
      color: #333333;
      margin: 5px;
    }

    &_ContentContainer {
      flex-direction: column;
      padding: 10px;
      padding-top: 43px;

      &_InputLabel {
        padding-right: 10px;
        width: 100%;
        color: #000000;
      }

      input {
        width: 100%;
        padding: 0 4px;
        margin-bottom: 15px;
      }

      &_ButtonsContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }
}
.QnASettings .FlowSettingsDialog_DialogContainer {
  height: 700px;
}

.QnASettings .FlowSettingsDialog_DialogContainer_ContentContainer {
  height: 100%;
  overflow-y: auto;
}
