.EventDialog {
  flex: 1;
  min-height: 0;

  &_label {
    margin: 20px 0 0 20px;
    color: #111111;
  }

  &_row {
    display: flex;

    input {
      margin: 20px 0 0 15px;
      padding: 23px 0 24px 5px;
      color: #222222;
      background-color: #f3f3f3;
      font-size: 17px;
      font-weight: 600;
      width: 70px;
      text-align: center;
    }
  }

  &_StartHourCheckbox {
    margin: 20px 0 0 11px;
    color: #111111;
    display: flex;
    align-items: baseline;
  }

  &_StartHourInput {
    input {
      margin: 20px 0 0 15px;
      padding: 23px 0 24px 5px;
      color: #222222;
      background-color: #f3f3f3;
      font-size: 17px;
      font-weight: 600;
      width: 70px;
      text-align: center;
    }
  }
}
