.ConflictsWrapper {
  display: flex;
  flex-direction: column;
  background-color: rgb(246, 195, 204);
  .text-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgb(246, 195, 204);
    .text-button {
    }
  }
  .chilren-wrapper {
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 10px;
    background-color: white;
  }
}
