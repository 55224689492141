.ConversationUser {
  flex-direction: row;
  display: flex;
  align-items: center;
  height: inherit;
  min-width: 0;

  .name-and-text-status {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 2px;
    min-width: 0;

    .user-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000;
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.408px;
    }

    .user-status-text {
      margin-top: 3px;
      float: left;
      text-align: left;
      color: #a3aed0;
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: -0.408px;
    }
  }

  .user-status-dot {
    position: relative;
    bottom: -12px;
    left: -8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #31a24c;
  }
}
