.FacebookConfigurationDialog_header {
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-right: 22px;
}

.FacebookConfigurationDialog {
  display: flex;
  flex-flow: column nowrap;
  padding: 22px;

  input {
    background-color: #f6f6f6;
    color: black;
    border: none;
    padding: 8px 20px;
    font-size: 14px;
    width: auto;
    height: 37px;
    border-radius: 4px;
  }

  &_Label {
    font-size: 18px;
    font-weight: bold;
    color: black;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &_facebookPagesContainers {
    &_Header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 96%;
      margin-top: 15px;

      &_Column {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33%;

        &_Title {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}
