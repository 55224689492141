.DocumentOutgoingMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .document-outgoing-file-message-img {
    margin-left: 16px;
    width: 16px;
    height: 16px;
  }
}
