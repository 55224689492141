.option {
  background: white;
  padding: 4px;
  cursor: pointer;
  width: auto;
  white-space: nowrap;
  color: black;
}

.option:hover {
  background: lightgrey;
}
