.Integration {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: space-between;

  .title {
    // width: 46px;
    height: 16px;
    font-size: 14px;
    font-weight: bold;
    color: #56595f;
  }
  .is-configurable {
    cursor: pointer;
  }
}
