.FlowListElement {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-right: 8px;

  &_Subcontainer {
    display: flex;
    flex-direction: row;

    &_Icon {
      width: 35px;
      height: 35px;
      border-radius: 10px;
      background-color: #d8d8d8;
      margin-right: 10px;
    }

    &_FlowTitle {
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333333;
      display: flex;
      flex-direction: row;
      align-items: center;

      &_FlowLanguage {
        margin-left: 6px;
        color: #c0c0c0;
      }
    }

    &_FlowMenuButton {
      padding: 10px;
      margin: 5px;
      cursor: pointer;

      &_ButtonDot {
        width: 3px;
        height: 3px;
        background-color: black;
        margin: 4px 0;
        border-radius: 3px;
      }
    }
  }
}
