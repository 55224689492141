.QnAConflicts {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f5f5f5;

    .back-button {
      cursor: pointer;
      margin-right: 10px;
    }

    .title-text {
      font-size: 20px;
      font-weight: bold;
      margin: auto;
    }
  }
  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .ignore-button {
      background-color: #ed4f2f !important;
      border: 1px solid white !important;
      color: white !important;
      margin: 10px;
    }
    .resolve-button {
      background-color: #ed4f2f !important;
      border: 1px solid white !important;
      color: white !important;
      margin: 10px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 90vh;
    overflow: auto;
    background-color: #ffffff;

    .options {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    #QnaEntriesContainer {
      overflow: auto;

      #QnaEntriesHeader {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        background-color: #eeeeee;
        font-weight: bold;
      }

      #EntryWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #dddddd;
      }
    }
  }
}
