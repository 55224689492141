.ProfileImageLabel {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  cursor: pointer;
}

.ProfileCamera {
  color: var(--main-blue);
  width: 60px !important;
  height: 60px !important;
  position: absolute;
}

.ProfileImage {
  border-radius: 50%;
  position: absolute;
}

.ProfileCircle:hover {
  .ProfileImage {
    -webkit-filter: contrast(50%);
    -moz-filter: contrast(50%);
    filter: contrast(50%);
  }

  .ProfileCamera {
    display: block !important;
    color: #fff;
  }
}

.ProfileCircle {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #fff;
}

.ProfileSaveButton {
  color: #fff !important;
  //font-weight: 600 !important;
}

.ProfileLogout {
  font-weight: 600;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
}

.ProfileLanguageSelect {
  width: calc(50% - 15px);
}

.ProfileInput {
  flex-grow: 1;
  //margin-top: 10px;
  //width: 45%;
}

.ProfileInputContainer {
  padding: 30px;
}

.ProfileCircle:hover {
  background: #e7ebf0;
}

#ProfilePhotoInput {
  display: none;
  cursor: none;
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}
.ProfileBack {
  left: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  position: absolute;
  cursor: pointer;
}
