.DatePicker {
  display: flex;
  background: #fff;
  cursor: pointer;
  padding: 0 10px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  align-items: center;
  margin-left: 16px;
  font-size: 16px;
  line-height: 35px;

  &:hover {
    border-color: rgba(34, 36, 38, 0.35);
  }
}
