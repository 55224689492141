.HTMLBlock {
  &_Input {
    font-size: 14px !important;
  }

  .HTMLBlock_HTMLContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .HTMLBlock_HTML.ui.input {
      width: 74%;
    }
  }

  .HTMLBlock_Dimensions {
    display: flex;
    justify-content: space-between;

    .FormGroup {
      width: 49%;
    }
  }
}
