:root {
  --main-blue: #0530f9;
}

.small-text {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: -0.408px;
  color: #ffffff;
}

.regular-text {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: -0.408px;
  color: #56595f;
}

.title-text {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 18px;
  letter-spacing: -0.408px;
  color: #56595f;
}

.option-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #56595f;
}

.dm-sans-400 {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.28px;
}

.dm-sans-500 {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.28px;
}

.dm-sans-700 {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.28px;
}
