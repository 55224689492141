.SimpleTextBlock {
  font-size: 14px;
  &_TextArea {
    font-family: Inter, sans-serif;
    border: none;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    resize: none;
    overflow: auto;
    padding: 8px;
  }

  &_OptionalSettings {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
