.CallcenterErrorHandlingMessages {
  &_ParameterContainer {
    margin-top: 22px;
    margin-bottom: 22px;
    width: 60%;

    input {
      margin-left: 22px;
    }
  }
}
