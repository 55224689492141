.headerButton {
  margin: 18px;
  padding: 5px 9px 3px 9px;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.deleteButton {
  cursor: pointer;
  /* flex: 0 1 auto; */
}
