.MediaAndFilesWidget {
  .media-and-files-container {
    position: relative;
    width: 97%;
    background-color: #fff;
    max-height: 150px;
    overflow-y: auto;
    overflow-x: clip;
    scrollbar-width: initial;
    z-index: 1;

    .media-and-files {
      display: flex;
      margin: 0 0 5px 5px;
      white-space: nowrap;
    }
  }
}
