.MetaCard {
  &_row {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    margin-left: 0px;

    &_icon {
      margin: 0px 4px;
      padding-top: 2px;

      svg {
        font-size: 17px;
        color: rgba(115, 115, 118, 1);
      }
    }

    &_icon.copy_icon {
      padding: 3px;
      align-items: baseline;
      border-width: 0px;
      box-sizing: border-box;
      display: inline-flex;
      font-size: inherit;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      border-radius: 10px;
      background: rgba(59, 149, 255, 0.16);
      cursor: pointer;
      height: 1.5em;
      line-height: 1.5em;

      transition:
        background 0.1s ease-out 0s,
        box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
      vertical-align: middle;
      width: 22px;
      outline: none !important;
      color: rgb(66, 82, 110) !important;

      .file-copy-icon {
        height: 14px;
        align-self: center;
        display: inline-flex;
        flex-wrap: nowrap;
        position: relative;
        fill: var(--main-blue);
      }
    }

    &_icon.copy_icon:hover {
      background: hsla(218, 76%, 15%, 0.1);
    }

    &_key {
      white-space: nowrap;
      width: 110px;
      height: 19px;
      margin: 0px 10px 0px 0px;
      align-items: center;

      line-height: 18px;
      color: rgba(86, 89, 95, 0.5);
      display: flex;
      align-items: center;
    }
    .contetn-wrapper {
      width: calc(100% - 120px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      flex-wrap: wrap;

      &_value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        height: 19px;

        font-weight: 600;
        line-height: 18px;
        display: flex;
        align-items: center;
      }
      .input {
        margin: 0 5px 0 5px;
        width: 95%;
        border: none;
        border-bottom: 1px solid #858585;
        &:focus-visible {
          outline: none;
          border: none;
          border-bottom: 1px solid #000;
        }
      }
    }
    .edit-button {
      margin-right: 10px;
      align-items: flex-end;
      border-width: 0px;
      box-sizing: border-box;
      font-size: inherit;
      font-style: normal;
      font-weight: 600;
      max-width: 100%;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      background: rgba(9, 30, 66, 0.04);
      border-radius: 3px;
      height: 1.5em;
      line-height: 1.5em;
      padding: 0px 8px;
      transition:
        background 0.1s ease-out 0s,
        box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
      vertical-align: middle;
      width: auto;
      outline: none !important;
      color: rgb(66, 82, 110) !important;
      cursor: pointer;
    }
  }
}
