.DeleteButton {
  margin: 18px;
  padding: 5px 9px 3px 9px;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border: 2px solid white;
  cursor: pointer;

  &.entity {
    background-color: rgb(118, 121, 196);
  }

  &.event {
    background-color: rgb(45, 75, 143);
  }

  &.child-connector {
    background-color: rgb(241, 191, 39);
  }

  &.intent {
    background-color: rgb(64, 140, 214);
  }

  &.message {
    background-color: rgb(64, 193, 214);
  }

  &.parent-connector {
    background-color: hsl(35, 85%, 57%);
  }

  &.service {
    background-color: rgb(152, 123, 116);
  }
}
