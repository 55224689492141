.ImageCard {
  max-width: 383px;
  border-radius: 15px;
  overflow: initial !important;

  .CardActionArea {
    padding: 0px;
    border: none;
    background-color: white;
    box-shadow: 0px 0px 2px #aaa;
    border-radius: 15px;
    width: 383px;
    max-width: 100%;
    max-height: 100%;

    .CardMedia {
      max-width: 100%;
      width: 383px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    .CardContent {
      a {
        text-decoration: unset;

        .ImageCardTitle {
          color: var(--main-blue);
          margin-left: 8px;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 600;
        }
      }

      .ImageCardParagraph {
        font-family: Inter, sans-serif;
        font-size: 14px;
        border: none;
        max-height: 100px;
        overflow-y: scroll;
        margin-bottom: 15px;
      }
    }
  }
}
