.LargeStatsWidget {
  display: flex;
  flex-direction: column;
  width: 32%;
  padding: 10px;
  min-height: 250px;

  border-left: 1px solid #c0c0c0;
  border-top: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
  border-bottom: 2px solid #c0c0c0;
  border-radius: 8px;

  .title {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    color: black;
    font-size: 16px;
    font-weight: 500;
    justify-content: space-between;
    .title-text {
      display: flex;
      align-items: flex-start;
      .help-icon {
        display: flex;
        align-self: center;
        width: 15px;
        margin: 0 0 0 5px;
      }
    }
    .setting-button {
      display: flex;
      width: 12px;
      height: 12px;
      align-items: center;
      justify-content: center;

      .setting-icon {
        display: flex;
      }
    }
  }
  .list {
    margin: 5px 0px 1px 0px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid rgb(239, 239, 239);
    color: black;

    .progress-bar-title {
      width: 40%;
    }
    .progress-bar-container {
      display: flex;
      width: 60%;
      flex-direction: row;
      justify-content: flex-end;

      .progress-bar-value {
        width: auto;
        padding-right: 5px;
      }
      .progress-bar {
        width: 70%;
        flex-wrap: nowrap;
      }
    }
  }

  .content-widget {
    display: flex;
    align-items: center;
    justify-content: center;
    .content-container {
      width: 80%;
      .content-title-text {
        position: absolute;
        display: flex;
        color: #1a90ff;
        font-size: 12;
        margin-top: -5;
      }
      .under-circular-progress-bar-text {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        margin-top: -30%;

        .comparison-text {
          display: flex;
          flex-direction: row;
        }
        .comparison-text-wrapper {
          display: flex;
          flex-direction: row;
          color: rgb(253, 110, 110);
          align-items: center;
          .arrow-icon-up {
            transform: rotate(180deg);
            fill: green;
            font-size: 1rem;
          }
          .arrow-icon {
            fill: red;
            font-size: 1rem;
          }
        }
      }
    }
  }
}
