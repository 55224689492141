.AudioOutgoingFileMessage {
  display: flex;
  align-items: center;

  .audio-outgoing-file-message-play {
    outline: none;
    cursor: pointer;
    display: inline-block;
    width: 0;
    height: 0;
    border: none;
    background: transparent;
    border-style: solid;
    border-width: 6px 0 6px 12px;
    border-color: transparent transparent transparent black;
  }
}
