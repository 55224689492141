.MessageThumbnail.left.center {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}

.MessageThumbnail.right.center {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}
