.ConversationRatings {
  display: flex;
  flex-direction: column;
  width: 100%;

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 10px 0 20px;

    .title {
      display: flex;
      color: black;
      align-items: flex-start;
      margin: 20px 0 0 0px;
      flex-direction: column;
      .title-container {
        display: flex;
        align-items: center;
      }
    }
    .value-change.value-green {
      color: rgba(26, 176, 87, 255);
    }
    .value-change.value-red {
      color: rgb(253, 110, 110);
    }
    .value-change {
      display: flex;
      color: rgb(253, 110, 110);
      align-items: center;
      padding-right: 5px;
      .arrow-icon {
      }
      .arrow-icon-down {
        transform: rotate(180deg);
      }
    }
    .under-title-text {
      color: black;
      margin: 0 0 0 5px;
    }
    .subtitle-text {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      align-items: center;
    }
    .legend {
      display: flex;
      flex-direction: column;
      padding: 10px 0 0 0;

      .legend-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        align-self: center;
        flex-wrap: nowrap;
        min-width: 105px;
      }
    }
  }
  .list {
    margin: 10px 0 0 0;
    .list-header {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      margin: 0 30px;
    }
    .line-item-container {
      margin: 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      .url {
        width: 55px;
      }

      .values-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 95%;

        .percentage-bar {
          height: 40px;
          width: 20px;
        }
      }
    }
  }
  .x-axis {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0 2% 0 20%;
  }
}
