.AnalyticsContainer {
  width: 100%;
  display: flex;
  overflow: auto;
  height: 100%;

  .reports-title {
    margin-top: 25px;
    margin-bottom: 55px;
    text-align: center;
  }
  .timezone-text {
    text-align: center;
    margin-bottom: 5px;
  }

  .body-style-primary {
    background-color: white;
    width: 100%;

    .body-style-container {
      width: 100%;
      max-height: 100%;
      overflow-y: scroll;
      padding: 20px;
    }

    .body-inner-container {
      // max-width: 75%;
    }

    .MainContent {
      width: calc(100% - 271px);
    }

    .filters-container {
      display: flex;
      flex-flow: column;
      margin-bottom: 40px;
      padding-top: 5px;
      padding-bottom: 15px;
      background-color: white;
      position: relative;

      .time-zone-text {
        margin-left: 20px;
        color: black;
      }

      .filters-label {
        font-weight: bold;
        color: #000000;
        font-size: 32px;
        margin-left: 20px;
      }

      .dropdown-container {
        margin-top: 8px;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
      }

      .export-options {
        background-color: white;
        position: relative;
        display: flex;

        justify-content: flex-end;

        .MuiButton-contained {
          box-shadow: none;
          color: black;
          background-color: white;
          border: 1px solid rgba(34, 36, 38, 0.15);
          border-radius: 10px;
        }
      }
    }

    .graph-container {
      position: relative;
    }
    .statistics-widgets {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      width: 100%;
      .statistics-widget {
        width: 200px;
        height: 80px;
        border: 1px solid rgba(34, 36, 38, 0.15);
      }
    }

    .row.lists-container {
      display: flex;
      flex-flow: row;
      margin-bottom: 40px;

      .column.list-container-left,
      .column.list-container-right {
        width: 50%;
        margin-right: 20px;
        border-bottom: 1px solid #c0c0c0;
        padding: 15px 10px;
        box-shadow: 0px 0px 5px #aaaaaa;
        background-color: white;
        position: relative;

        .filters-label {
          font-weight: bold;
          color: #000000;
          font-size: 20px;
          margin-left: 20px;
          margin-top: 10px;
        }
      }
    }

    .row-container {
      background-color: white;
      margin-bottom: 40px;
      width: 100%;
      height: 460px;
      border-radius: 8px;

      .graphs-row {
        flex-direction: row;
        min-height: 470px;
        border-left: 1px solid #c0c0c0;
        border-top: 1px solid #c0c0c0;
        border-right: 1px solid #c0c0c0;
        border-bottom: 2px solid #c0c0c0;
        border-radius: 8px;
      }
    }

    .row-container-table {
      background-color: white;
      box-shadow: 0px 0px 5px #aaaaaa;
      margin-bottom: 40px;
      width: 100%;
      border-radius: 8px;

      .table-row {
        flex-direction: row;
        border-radius: 8px;

        .table-element {
          border: 1px solid #c0c0c0;
        }
      }
    }

    .intents-filter-button {
      position: relative;
    }
  }

  .general-average {
    padding-left: 6px;
    border-left: solid 5px #334bfa;
    margin-bottom: 30px;
  }
}
