.FileInput {
  display: flex;
  justify-content: center;
  align-items: center;

  .input {
    display: none;
    cursor: none;
  }

  .attach-button-icon {
    cursor: pointer;
  }
}

.attach-button-icon:hover {
  stroke: #3c95ff;
}
