.ConversationDetails {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffff;
  box-sizing: border-box;
  width: 100%;
  z-index: 1000;

  display: flex;
  flex-direction: column;

  overflow: auto;

  .return-button-container {
    display: flex;
    justify-content: flex-start;
  }
}
