.CRMContainer {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  .CRM {
    flex: 1;
    display: flex;
    overflow: hidden;
  }

  .ContentContainer {
    background-color: #f3f2f3;
    flex: 1;
  }
}
