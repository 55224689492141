.Notifications {
  position: fixed;
  max-width: 500px;
  width: 100%;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  z-index: 9999999;
  &_Item {
    z-index: 9999999;
    margin: 4px 0 !important;
    transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease,
      transform 0.5s !important;
    transform: translateY(-1000px) !important;
    &_Animated {
      transform: translateY(0) !important;
    }
  }
}
