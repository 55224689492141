.ConversationConsole {
  position: relative;

  margin: 1px 15px 15px;
  margin-bottom: 31px;

  border-radius: 16px;

  background-color: white;
  box-sizing: border-box;

  z-index: 10;

  display: flex;
  flex-direction: column;

  .conversation-composer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    ::placeholder {
      font-style: italic;
      color: rgba(168, 175, 185, 0.68);
    }

    .MessageInput {
      width: 100%;
      border: none;

      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.21px;
      color: #2b3674 !important;
      resize: none;

      padding: 8px 24px 4px 10px;
      border: none;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      border-color: #f1f1f2;
      outline: none;

      max-height: calc(50vh - 90px);
      overflow: auto;
      background-color: white;
    }

    .conversation-controls {
      padding: 0 5px 5px;
      margin-left: 3px;

      .attach-documents-form {
        display: flex;
        flex-direction: row;
        margin-top: 4px;

        .left {
          // float: left !important;
          width: 45px;
          height: 45px;
          flex-shrink: 0;
          border-radius: 10px;
          background-color: #f4f7fe;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
        }

        .right {
          float: right !important;
          margin-left: 5px;
          width: 45px;
          height: 45px;
          flex-shrink: 0;
          border-radius: 10px;
          background-color: #2a86ff;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;

          .send-button-icon {
            width: 23px;
            height: auto;
            fill: #3c95ff;
            cursor: pointer;
          }
        }
      }
    }
  }

  .pane-selector {
    box-sizing: border-box;
    padding-left: 15px !important;
    padding-bottom: 10px !important;
    width: 100%;
    overflow: auto;
  }

  .conversation-control {
    z-index: 10;
    position: relative;
  }

  .AttachedFile_wrapper {
    display: flex;
    flex-direction: row;

    .AttachedFile {
      display: flex;
      align-items: center;
      // background-color: #ccc;
      border-radius: 8px;
      padding: 8px;
      width: auto;
      margin-right: 8px;
    }

    .deleteIcon {
      height: 16px;
      width: 16px;
      margin-left: 8px;
      cursor: pointer;
    }
  }
}

.send-button-icon :hover {
  stroke: #3c95ff;
}
