.ProjectsList {
  display: flex;
  flex-direction: row;
  justify-content: left;

  .nav {
    @media screen and (max-width: 600px) {
      display: none;
    }

    width: 271px;
    flex-shrink: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    #LogoContainer {
      padding-top: 43px;
      padding-bottom: 43px;
      border-bottom: 1px solid #f4f7fe;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 35px;
    }

    #logo {
      width: 197.695px;
      flex-shrink: 0;
    }

    .link:hover {
      background-color: #f4f7fe;
      width: 90%;
      border-radius: 10px;
    }

    .link {
      padding: 3px 6px 3px 6px;
      margin-left: 19px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        margin-right: 5px;
      }

      .text {
        color: #a3aed0;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.32px;
      }
    }
  }

  #content {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background: var(--secondary-grey-300, #f4f7fe);

    #ProjectsContainer {
      height: 100%;
      #MobileHeader {
        display: none;

        @media screen and (max-width: 600px) {
          margin-left: -10px;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          width: 300px;

          color: black;
          font-size: 22px;
          margin-bottom: 15px;
          margin-top: 15px;

          .title {
            margin-left: 5px;
          }
        }
      }

      .CreateProjectButton {
        @media screen and (max-width: 600px) {
          display: none;
        }

        position: fixed;
        top: 32px;
        right: 36px;
        font-size: 14px;
        border-radius: 10px;
        padding: 10px;
      }

      #ProjectsList {
        @media screen and (max-width: 600px) {
          margin-top: 0;
          flex-flow: column nowrap;
          align-items: center;
        }

        margin-top: 100px;
        display: flex;
        flex-flow: row wrap;

        .ProjectCard {
          @media screen and (max-width: 600px) {
            flex-shrink: 0;
            width: 300px;
            height: 50px;
            margin: 10px;
          }

          width: 300px;
          height: 300px;
          margin: 20px 0 20px 20px;
          background: white;
          border-radius: 20px;
          padding: 10px 15px;

          .title {
            color: #1b2559;
            font-size: 18px;
            line-height: 100%;
            letter-spacing: -0.4px;
          }
        }
      }
    }
  }
}
