.blue-button-style {
  background-color: #0530f9 !important;
  color: #fff !important;
  padding: 10px !important;
}
.red-button-style {
  background-color: #f50057 !important;
  color: #fff !important;
  padding: 10px !important;
}

.header {
  color: black;
}

.button-as-link {
  color: #0530f9;
  cursor: pointer;
}
