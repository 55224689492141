$error: #e61400;
$background: #ffe8e5;

.FormErrors {
  margin: 15px 0;
  padding: 15px;
  border: 1px solid $error;
  color: $error;
  font-weight: 600;
  background-color: $background;
  border-radius: 2px;
  font-size: 14px;
  text-align: center;
}
