@use 'sass:math';

$height: 62px;

.NavBar {
  display: flex;
  flex-flow: row;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
  height: ($height);
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  .menu-left {
    display: flex;
    flex-flow: row;
    text-align: center;
    flex: 4;

    .back-button {
      margin-left: math.div($height - 36, 2);
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    text-align: center;
    background-color: #f3f3f3;
    border-radius: 19px;
    padding: 4px 29px;
  }

  .menu-right {
    display: flex;
    flex-flow: row;
    text-align: center;
    justify-content: flex-end;
    flex: 4;

    .menu-right-buttons {
      display: flex;
      flex-flow: row;
      align-content: space-around;
      margin-left: 30px;
      padding-left: 1px;
      background-color: #cccccc;
    }
  }
}

.NavBar.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
