.macrosContainer {
  border: 1px solid #ccc;
  border-radius: 16px;
  width: 100%;
  display: flex;
  margin: 24px;
  background-color: #fff;

  .addMacroContainer {
    padding: 18px;

    button {
      padding: 12px;
      border: none;
      border-radius: 8px;
      background-color: #ddd;
      cursor: pointer;
    }

    button:hover {
      background-color: #aaa;
    }
  }

  .macrosMenu {
    width: 25%;
    height: 100%;
    border-right: 1px solid #ccc;
    overflow-y: scroll;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;

    .macrosPersonal {
      width: 100%;
    }

    .macrosShared {
      width: 100%;
    }

    .macrosListTitleContainer {
      width: 100%;
      padding: 16px;
      background-color: #ddd;
      color: #666;
      font-size: 16px;
    }

    ul {
      list-style-type: none;
      font-size: 16px;
      padding-left: 16px;
      margin: 8px 0;
      cursor: pointer;
    }

    li {
      padding: 4px 0;
    }
  }

  .macrosContent {
    width: 75%;
    padding: 16px;

    .macrosContentHeader {
      display: flex;
      padding-bottom: 16px;

      .macrosContentHeaderTitle {
        width: 75%;
        text-decoration: solid;
        font-size: 18px;
        font-weight: 600;
      }

      .macrosContentHeaderActions {
        width: 25%;
        display: flex;
        justify-content: right;

        .macrosActionDelete {
          margin-right: 12px;

          padding: 12px 16px;
          border: none;
          border-radius: 8px;
          background-color: pink;
          color: #8e1438;
          font-weight: 600;
          letter-spacing: 0.5px;
          cursor: pointer;
        }
        .macrosActionDelete:hover {
          background-color: rgb(199, 136, 146);
        }

        .macrosActionDeleteDisabled {
          margin-right: 12px;

          padding: 12px 16px;
          border: none;
          border-radius: 8px;
          background-color: #ccc;
          color: #aaa;
          font-weight: 600;
          letter-spacing: 0.5px;
          cursor: pointer;
        }

        .macrosActionSave {
          padding: 12px 16px;
          border: none;
          border-radius: 8px;
          background-color: lightgreen;
          color: darkgreen;
          font-weight: 600;
          letter-spacing: 0.5px;
          cursor: pointer;
        }

        .macrosActionSave:hover {
          background-color: rgb(105, 184, 105);
        }

        .macrosActionSaveDisabled {
          padding: 12px 16px;
          border: none;
          border-radius: 8px;
          background-color: #ccc;
          color: #aaa;
          font-weight: 600;
          letter-spacing: 0.5px;
          cursor: pointer;
        }
      }
    }

    .macrosContentBody {
      padding-bottom: 16px;
    }

    .textareaMacro {
      width: 100%;
      min-height: 20px;
    }

    .macrosContentFooter {
      .macrosContentFooterLabel {
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 8px;
      }
      .availableSelect {
        padding: 16px;
        cursor: pointer;
      }
    }

    .macrosError {
      padding-top: 16px;
      font-weight: 600;
      color: darkred;
    }
  }
}
