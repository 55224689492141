.WebchatConfigurationDialog_Header {
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-right: 22px;
}

.WebchatConfigurationDialog {
  &_Content {
    padding: 30px;

    &_EmbedingScriptContainer {
      width: 100%;
      overflow: auto;
    }

    &_Options {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &_ColumnLeft {
        display: flex;
        flex-direction: column;
        width: 50%;

        &_OptionContainer {
          display: flex;
          flex-direction: row;
          padding: 10px;
          align-items: center;
        }

        &_OptionTitle {
          font-weight: 600;
          color: #56595f;
        }
      }

      &_ColumnRight {
        display: flex;
        flex-direction: column;
        width: 50%;

        .Title {
          width: max-content;
          margin: 0px 10px 0px 0px;
          align-self: center;
        }

        &_OptionContainer {
          display: flex;
          flex-direction: row;
          padding: 10px;
          align-items: center;
          justify-content: space-between;
          height: 56px;

          &_OptionTitle {
            font-weight: 600;
            color: #56595f;
            max-width: 310px;
          }
        }
      }
    }
  }
}

.WoocommerceConfiguration {
  padding: 5px 10px;
  width: auto;
  font-weight: 600;
  color: #56595f;

  .Title {
    display: flex;
    align-items: center;
  }

  &_Input {
    width: 50%;
  }
}
.tooltip_popper {
  z-index: 9001 !important;
}
.postion_input_box_wrapper {
  margin-top: 5px;
  color: #56595f;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  .postion_input_box {
    color: #56595f;
    width: 90px;
    left: 0px;
    height: 30px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    box-shadow: none;
    margin-left: 5px;
  }
}

.CompactDropdown {
  padding: 0 30px 0 15px !important;
  min-height: 35px !important;

  & .icon {
    padding: 8px !important;
    top: 50%;
  }
  & .text {
    line-height: 35px;
    font-size: 16px;
  }
}

.IntegrationVoicesContainer {
  padding: 10px;
}

.IntegrationVoicesRow {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 15px;
}
