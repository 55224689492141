.SettingTabGenerativeAIConfiguration {
  display: flex;
  flex-direction: row;
  align-items: center;

  .tooltip {
    margin-left: 12px;
  }
  .dropDown {
    margin-left: 12px;
  }
}
