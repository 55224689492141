.ConversationsSearchContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: center;

  .search-input-container {
    display: flex;
    flex-direction: row;

    grid-gap: unset;
    gap: unset;

    .search-input-back-button {
      height: 32px;
      justify-content: center;
      margin-right: 16px;

      display: flex;
      align-items: center;

      border: none;
      background-color: white;

      cursor: pointer;
    }

    .search-input-component {
      position: relative;

      min-height: 10px;

      margin-right: 5px;
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      border-radius: 6px;
      width: 100%;

      grid-gap: unset;
      gap: unset;

      border-width: 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.1);

      .menu-list {
        z-index: 10;
        top: 40px;
        position: absolute;
      }
      .search-input-wrapper {
        display: flex;
        align-items: center;

        .search-input-assembly {
          position: relative;
          padding-right: 10px;
          box-sizing: border-box;
          width: 100%;
          min-height: 10px;
          line-height: 20px;
          color: #ffffff;
          outline: 0;
          display: flex;
          flex: 1 1;
          flex-wrap: nowrap;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column-reverse;

          .search-pill {
            height: 100%;
            position: relative;
            border-radius: 3px;
            color: #222;
            background: #ffffff;
            display: inline-flex;
            align-items: center;
            margin-right: 4px;
            width: min-content;
            z-index: 100;
            cursor: default;
          }
          .delete-button.cancel-filter {
            margin-left: 4px;
            margin-right: 4px;
            position: relative;
            right: 0px;
            font-size: 1.2rem;
            color: #334bfa;
            display: none;
            cursor: pointer;
          }

          .search-pill:hover,
          .search-pill.highlight {
            color: #334bfa;

            .delete-button.cancel-filter {
              display: inherit;
              cursor: pointer;
            }
          }

          .search-pill.current-filter {
            background: e6e6e6;
          }

          .search-input {
            border: 0;
            outline: 0;
            width: auto;
            min-width: 60px;
            flex: auto;
            background: #ffffff;
            z-index: 100;
            font-size: 14px;
          }
        }
        .delete-button {
          width: 16px;
          height: 16px;
          vertical-align: bottom;
          margin-bottom: 2px;
          margin-right: 5px;
          color: black;
          fill: black;

          display: inline-flex;
          //align-self: flex-end;
          cursor: pointer;
        }

        .delete-button:hover {
          color: #334bfa;
          cursor: pointer;
        }
      }

      .MuiList-root.MuiList-padding {
        //top: 0px !important;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: white;
        padding: 0px;
        z-index: 101;
      }

      .icon {
        margin-right: 7px;
      }
    }
  }

  .ConversationsInboxContainer {
    position: absolute;
    top: 65px;
    left: 0px;
    width: 100%;
    height: calc(100% - 67px);
  }
}

.search-input-wrapper-isMobile {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
.search-input-assembly-isMobile {
  position: relative !important;

  box-sizing: border-box !important;
  width: 100% !important;
  min-height: 40px !important;
  padding: 5px 32px 5px 5px !important;

  line-height: 20px;
  color: #fff;
  outline: 0;
}

.search-input-container-isMobile {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 5px;
  padding-right: 0px;

  display: flex;
  flex-direction: row;

  grid-gap: unset;
  gap: unset;
}

.search-pill-isMobile {
  height: 100%;
  position: relative;
  border-radius: 3px;
  color: #222;
  background: #e8e8e8;
  display: inline-flex;
  align-items: center;
  margin-right: 4px;
  width: max-content;
  padding: 10px;
}
