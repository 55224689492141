.MenuContainer {
  // background-color: #FAFAFF;
  background-color: white;
  color: #737376;
  flex-shrink: 0;
  height: 100%;

  .collapse-container {
    position: absolute;
    bottom: 10px;
    cursor: pointer;
  }
}
