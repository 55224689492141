.NotesCardContent {
  padding: 0 0px 10px 0px;

  textarea {
    resize: none;
    min-height: 20px;
    line-height: 18px;
    padding: 5px 8px;
    background-color: rgba(255, 255, 255, 1);
    color: rgb(34, 34, 34);

    box-sizing: border-box;
    width: 100%;

    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }
  ::placeholder {
    font-style: italic;
    color: rgba(168, 175, 185, 0.68);
  }
}
