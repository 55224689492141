.DepartmentManagement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .manage-department-title-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 30px 0 30px 0;

    .header {
      color: black;
    }

    .back-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 30px;
      margin: 0 20px 0 0;
    }
  }
  .departement-management-buttons {
    width: 60%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .agent-management {
    .agent-management-header {
      color: black;
      margin: 20px 10px;
    }
  }
  .emails {
    .email-management-header {
      color: black;
      margin: 20px 10px;
    }
  }
}
