.Integrations {
  width: 50%;
  max-width: 400px;
  height: calc(100% - 50px);
  margin-left: 10%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &_ScrollableContainer {
    margin-top: 20px;
    height: 100%;
    width: 100%;
    overflow-y: auto;

    &_Paragraph {
      width: 100%;
      max-width: 400px;
    }
  }
}
