.ConversationSummary {
  padding: 23px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-bottom: 1px solid rgba(217, 217, 217, 1);

  .Title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 5px 0px;
    width: 100%;
    height: 19px;
    font-size: 19px;
  }

  .Id,
  .Subject {
    margin: 7px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .content.empty {
      color: rgba(115, 115, 118, 1);
    }
  }

  .label {
    color: rgba(115, 115, 118, 1);
    margin-right: 12px;
  }
}
