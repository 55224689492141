.projectDetails-container-style {
  display: flex;
  width: calc(100% - 271px);
  height: 100%;
  position: relative;

  .content-style {
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 4px 24px 2px rgba(0, 0, 0, 0.01);
  }
}

.DashboardCollapsed .projectDetails-container-style {
  width: calc(100% - 105px);
}
