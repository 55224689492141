.Card {
  margin-bottom: 40px;
  border-bottom: 1px solid rgb(192, 192, 192);
  padding: 25px;
  box-shadow: rgb(170, 170, 170) 0px 0px 5px;
  background-color: white;
  border-radius: 2px;
  // &:last-of-type {
  //   margin-bottom: 0;
  // }
}
