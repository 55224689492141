.ListWidget {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  .title {
    display: flex;
    color: black;
    align-items: center;
    margin: 30px 0 0 20px;
  }
  .list {
    margin: 40px 0 0 0;
    .list-header {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin: 0 30px 0 15px;
      color: black;
    }
    .lineItemContainer {
      margin: 15px;
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.2);

      .url {
        color: black;
      }
      .valuesContainer {
        display: flex;
        align-items: center;
        flex-direction: row;
        min-width: 100px;
        justify-content: flex-start;

        .numberOfConversations {
          color: black;
        }
        .difference {
          display: flex;
          font-size: 12px;
          align-items: center;

          .arrow-icon {
          }
          .arrow-icon-down {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
