.CRMDashboard {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;

  .Header {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid lightgrey;
  }

  .MainContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;

    .Containers {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      background: #fff;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 4px 24px 2px rgba(0, 0, 0, 0.01);
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

      .conversation-list-header-mobile {
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 3% 5% 1%;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 10px;

        .list-icon {
          margin-left: 5px;
          margin-right: 5px;
          color: black;
        }
        .search-field-not-active {
          width: 100%;
        }
      }

      .search-page-overlay {
        height: 95%;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          font-size: 60px;
          color: darkgrey;
        }

        .search-warning {
          font-size: 20px;
        }
      }
      .ConversationInboxSupportInfo {
        position: absolute;
        width: 23%;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: auto;

        // background: #FAFAFF;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
      }
    }
  }
  .float-button {
    background-color: white;
    position: absolute;
    right: 30px;
    bottom: 30px;
    z-index: 1;
    .new-conversation-image {
      max-width: 24px;
      height: auto;
    }
  }
}

.hamburger-menu-mobile-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
}

.is-mobile-modifier-CRM-Dashboard {
  width: 100% !important;
}

.left-bar-mobile-menu-drawer {
  overflow-x: hidden;
  width: 285px;
}

@keyframes vertical_bounce {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-8px);
  }

  20% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-4px);
  }

  35% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}
