.Participants {
  .participant {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-left: 10px;

    .image {
    }
    .participant_and_patron {
      .name {
        margin-bottom: 5px;
        margin-top: 5px;

        font-weight: 600;
        font-size: 0.9375rem;
        line-height: 1.3333;

        word-wrap: break-word;
      }
      .patron {
        margin-bottom: 5px;
        margin-top: 5px;

        font-weight: normal;
        font-size: 0.8125rem;
        line-height: 1.2308;

        word-wrap: break-word;

        color: #65676b;
      }
    }
    .options {
    }
  }

  .add-participant {
    .add-button:hover {
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.04);
    }

    .add-button-name {
      margin-bottom: 5px;
      margin-top: 5px;

      font-weight: 600;
      font-size: 0.9375rem;
      line-height: 1.3333;

      word-wrap: break-word;
    }
  }
}
