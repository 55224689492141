.RandomMessageBlock {
  &_Form {
    display: flex;
    flex-direction: column;

    &_Container {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
    }

    &_Chip {
      position: relative;
      font-size: 14px;
      border: solid 1px lightgray;
      display: flex;
      flex-direction: row;
      border-radius: 8px;
      height: auto;
      flex-grow: 2;
      //flex-shrink: 0;
      color: #fff;
      overflow: hidden;
      //padding: 1px 4px;
      margin-right: 4px;
      margin-bottom: 4px;
      &_Add {
        height: 24px;
        //color: #676565;
        //background: #fff;
        //border: 1px solid lightgray;
        //cursor: pointer;
        //display: flex;
        //align-items: center;
        //justify-content: center;
      }
      &_Save {
        background: salmon;
      }
      &_Icon {
        position: absolute;
        top: 0;
        right: 0;
        color: #676565;
        cursor: pointer;
      }
      &_Input {
        border: none;
        //outline: none;
        height: 100%;
        width: 100%;
        //color: #fff;
        //flex-grow: 1;
        min-width: 90px;
        max-width: 100%;
        border-radius: 8px;
        resize: none;
        overflow: auto;
        padding: 8px 16px 8px 8px;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      &_Input:active {
        border: none;
      }
    }
  }

  &_OptionalSettings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 160px;
    margin-bottom: 4px;
  }

  &_TextArea {
    //flex-grow: 1;
    font-family: Inter, sans-serif;
    border: solid 1px lightgray;
    //outline: none;
    height: auto;
    //color: #fff;
    width: 90%;
    min-width: 90px;
    max-width: 100%;
    margin-right: 4px;
    border-radius: 8px;
    resize: none;
    overflow: auto;
    padding: 8px 14px 8px 8px;
    font-size: 14px;
    margin-bottom: 4px;
  }
}
