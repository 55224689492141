.Departments {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .header {
    margin: 30px 0 30px 0;
    color: black;
  }

  .invite-user-button {
    margin: 15px 0 15px 10px;
  }
  .button-as-link {
    color: #0530f9;
    cursor: pointer;
  }
}
