.LogMessage {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .log {
    padding: 3px 12px 5px 12px;
    margin: 9px 0;
    max-width: 100%;
    resize: none;
    word-wrap: break-word;

    border-color: transparent;
    align-self: center;
    font-weight: 600;
    line-height: 15px;
    text-align: center;
    color: #2a86ff;

    border-radius: 10px;
  }

  .audit {
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    margin: 0 0.14285714em;
    background-color: #e8e8e8;
    background-image: none;
    padding: 0.5833em 0.833em;
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
    font-weight: bold;
    border: 0 solid transparent;
    border-radius: 0.28571429rem;
    transition: background 0.1s ease;
  }
}
