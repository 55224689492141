.HeatMapWidget {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .title-container {
    display: flex;
    flex-direction: row;
    margin: 20px;
    .title {
      margin-left: 10px;
      color: black;
      display: flex;
      align-items: center;
    }
    .help-icon {
      display: flex;
      align-self: center;
      width: 15px;
      margin: 0 0 0 5px;
    }
  }
  .heat-map-wrapper {
    display: flex;
    flex-direction: row;
    margin: 0 20px 0 10px;
    .y-axis-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .y-axis-label {
        display: flex;
        justify-content: flex-start;
        margin: 10px;
        align-items: center;
        color: black;
      }
    }
    .grid-container {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(24, 1fr);
      grid-template-rows: repeat(7, 1fr);
      justify-content: space-between;
      align-items: stretch;

      .grid-item {
        display: flex;
        background-color: rgba(255, 255, 255, 0.8);
        border: 0.2px solid #f8f6f6;
        text-align: center;
        min-height: 40px;
        min-width: 40px;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .x-axis-wrapper {
    display: flex;
    flex-direction: row;
    margin: 20px 25px 0 65px;
    justify-content: space-between;
    .x-axis-label {
      display: flex;
      justify-content: center;
      min-width: 40px;
      color: black;
    }
  }
  .colour-legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    .gradient-stripe {
      margin: 0 2px 0 2px;
      height: 25px;
      width: 200px;
      border: 1px solid rgb(154 154 154 / 80%);
      background: linear-gradient(90deg, rgba(0, 45, 150, 0), rgba(0, 45, 150, 1));
    }
  }
}
