.MessageBlocks {
  &_AddButtons {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    & > button {
      // display: inline-block !important;
      outline: none;
      cursor: pointer;
      margin-right: 8px;
    }
  }

  & .TitleContainer {
    display: flex;
    align-items: center;
    font-weight: bold !important;
    font-size: 18px !important;
    color: #56595f;
    margin-bottom: 8px;
  }

  &_BlocksContainer {
    min-height: 20px;
    margin-top: 8px;
    padding: 6px;
  }
}

.BlockFactory {
  padding: 8px;
  background: #fff;
  border: 1px solid lightgray;
  border-radius: 8px;

  &_Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #56595f;

    &_Title {
      margin: 0;
      cursor: pointer;

      &:hover {
        color: grey;
      }
    }

    &_DeleteIcon {
      cursor: pointer;

      &:hover {
        color: #d44141;
      }
    }

    &_CollapseIcon {
      cursor: pointer;

      &:hover {
        color: grey;
      }
    }

    &_MoveIcon {
      &:hover {
        color: grey;
      }
    }
  }

  &_Content {
    margin-top: 8px;
  }
}
