.SmallStatsWidget {
  display: flex;
  flex-direction: column;
  width: 32%;
  padding: 10px;

  border-left: 1px solid #c0c0c0;
  border-top: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
  border-bottom: 2px solid #c0c0c0;
  border-radius: 8px;
  .title {
    min-height: 23px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: black;
    font-size: 16px;
    font-weight: 500;
    .help-icon {
      display: flex;
      align-self: center;
      width: 15px;
      margin: 0 0 0 5px;
    }
  }
  .content-value {
    display: flex;
    align-items: center;
    .value-change.value-green {
      color: rgba(26, 176, 87, 255);
    }
    .value-change.value-red {
      color: rgb(253, 110, 110);
    }
    .value-change {
      display: flex;
      align-items: center;
      .arrow-icon {
      }
      .arrow-icon-down {
        transform: rotate(180deg);
      }
    }
    .under-title-text {
      color: black;
      margin: 0 0 0 5px;
    }
  }
}
