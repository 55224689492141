.ConversationStreamContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  height: 100%;

  display: flex;
  flex-direction: column;

  background-color: #f4f7fe;
  font-size: 14px;
  line-height: 20px;

  .macrosList {
    list-style: none;
    cursor: pointer;
    padding: 0;
    margin: 4px 15px;
    border: 1px solid #aaa;
    border-radius: 4px;

    li {
      padding: 5px;
    }

    li:hover {
      background-color: #ccc;
    }

    .macroTitle {
      font-weight: 600;
    }
  }
}
