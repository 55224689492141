.UserManagement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .manage-users-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 30px 0 30px 0;

    .header {
      margin: 5px 0 10px 0;
      color: black;
    }
    .back-button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 50px;
      height: 30px;
      margin: 0 20px 0 0;
    }
  }
  .content-wrapper {
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;

    .content-container {
      width: 40%;
      .content-header {
      }
      .contentcontent-list {
      }
    }
  }
  .button-update-container {
    margin: 30px 0 0 0;
    padding: 0 15px 0 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .update-button {
    }
  }
}
