.ConversationInboxListContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 342px;
  box-sizing: border-box;
  border-right: 1px solid #eef3ff;
  display: flex;
  flex-flow: column nowrap;

  .search-wrapper {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: 10px;
    border: 1px solid #eef3ff;
    padding: 4px 5px 4px 10px;
    background: #ffffff;
    margin: 15px 10px 15px 10px;
    width: auto;
    min-height: 31px;
    cursor: text;

    .search-button {
      flex-shrink: 0;
      align-items: baseline;
      border-width: 0px;
      display: inline-flex;
      text-decoration: none;
      white-space: nowrap;
      background: transparent;
      border-radius: 50%;
      height: 30px;
      line-height: 1.5em;
      vertical-align: middle;
      width: 30px;
      outline: none !important;
      color: rgb(66, 82, 110) !important;

      cursor: pointer;

      .search-button-icon {
        align-self: center;
        display: inline-flex;
        flex-wrap: nowrap;
        max-width: 100%;
        position: relative;
      }
    }

    .search-button:hover {
      background-color: #f4f7fe;
    }
  }

  .list-header-wraper {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #eef3ff;
    height: 76px;

    .list-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-bottom: 1px solid #fff;
      box-sizing: border-box;
      padding: 0 0px 0 12px;

      color: #000;
      font-family: DM Sans;
      font-size: 19px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: -0.408px;

      .list-icon {
        font-size: large;
      }

      .span {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        display: block !important;
      }

      .new-conversation-button {
        margin-top: 8px;
        height: 25px;
        min-width: 20px;
        padding: 8px;
        background: white;
        border-radius: 8px;
        color: #56595f;
        text-transform: none;
        color: #ffffff;
        background: #56595f;
      }
    }
    .list-filter {
      padding: 13px 11px 5px 11px;
      background-color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;

      .open-close-button {
        height: 25px;
        min-width: 20px;
        padding: 8px;
        background: white;
        border-radius: 8px;
        color: #56595f;
        text-transform: none;

        &.active {
          color: #ffffff;
          background: #56595f;
        }
        .MuiButton-label {
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.408px;
        }
      }
    }
  }

  .list {
    border-top: 1px solid #eef3ff;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    overflow: auto;
    z-index: 1;

    .post-message-block {
      position: absolute;
      left: 0;
      right: 0;
      top: 112px;
    }
  }
  .list-is-mobile {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .empty-folder-note {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media all and (max-width: 1280px) {
  .ConversationInboxListContainer {
    width: 300px;
  }
}
.ConversationInboxListContainer-is-mobile {
  width: 100%;
}

.list-header-wraper-isMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
}
