.CollapsibleInfoCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  min-height: 50px;
  padding: 5px 0;

  .collapsible-button {
    display: inline-flex;
    height: 1.5em;
    line-height: 1.5em;
    padding: 0px 0px;
    vertical-align: middle;
    width: auto;
    margin-right: 5px;

    width: 24px;
    height: 24px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;

    cursor: pointer;
  }

  .collapsible-button:hover {
    background: hsla(218, 76%, 15%, 0.1);
  }
}
