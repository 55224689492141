.BlocksIncomingMessage {
  margin-left: 22px;
  margin-top: 9px;
  margin-bottom: 9px;
  margin-right: 8px;
  max-width: 800px;
  align-self: end;
  color: white;
}

.BlocksIncomingMessage.HTML_BLOCK {
  background-color: transparent;
  border-radius: 15px 15px 15px 0px;
  border-color: transparent;
  resize: none;
  word-wrap: break-word;
  font-family: Inter, sans-serif;
  font-size: 14px;
}

.BlocksIncomingMessage.IMAGE_BLOCK {
  max-width: 383px;
}

.BlocksIncomingMessage.CAROUSEL_BLOCK {
  max-width: 383px;
  padding: 8px 0px;
}

.BlocksIncomingMessage.SIMPLE_TEXT_BLOCK,
.BlocksIncomingMessage.RANDOM_MESSAGE_BLOCK,
.BlocksIncomingMessage.LINK_TEXT_BLOCK {
  padding: 16px 24px;
  background-color: var(--main-blue);
  border-color: transparent;
  max-width: 289px;
  resize: none;
  word-wrap: break-word;
  font-family: Inter, sans-serif;
  font-size: 14px;
  white-space: pre-wrap;
  border-radius: 15px;
}

.BlocksIncomingMessage.LINK_TEXT_BLOCK {
  border-radius: 15px;
  background-color: #f1f1f2;
  border-color: transparent;
  box-shadow: 0px 0px 2px #aaaaaa;

  a {
    text-decoration: underline;
  }
}
