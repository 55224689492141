.InputFieldsForModels {
  display: flex;
  flex-direction: column;
  margin: 30px;
  width: 50%;

  .InputFieldsForModelsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
  }
}
