.MenuComponent {
  // background-color: #FAFAFF;
  color: #737376;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .logo-container {
    padding-top: 50px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .top-section-title {
    border-top: 1px solid #eef3ff;
    padding-top: 42px;
  }

  .section-title {
    color: #a3aed0;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.28px;
    text-transform: uppercase;
    margin: 21px 0 -3px 19px;
  }

  .section-container {
    display: flex;
    flex-direction: row;
  }
}

.MenuComponentCollapsed {
  height: calc(100% - 70px);

  .submenu-file {
    margin-left: 0;
    margin-right: 0;
    padding-left: 7px;
  }
  .submenu-section {
    margin-left: 0;
    margin-right: 0;
    padding-left: 7px;
  }
}
