.DuplicateWarnings {
  display: flex;
  flex-direction: column;
  background-color: #ed4f2f;

  .warning-content {
    color: white;
    margin: 10px;
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .ignore-button {
      background-color: #ed4f2f !important;
      border: 1px solid white !important;
      color: white !important;
      margin: 10px;
    }
    .resolve-button {
      background-color: #ed4f2f !important;
      border: 1px solid white !important;
      color: white !important;
      margin: 10px;
    }
  }
}
