.ImageBlock {
  &_Input {
    font-size: 14px !important;
  }

  .fileContainer {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    padding: 0px;
    margin: 0px;
  }

  .fileContainer .chooseFileButton {
    background: white;
    color: rgb(51, 51, 51);
    border: 2px solid rgb(64, 193, 214);
    font-size: 14px;
    font-weight: 600;
  }

  .ImageBlock_ImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ImageBlock_Image.ui.input {
      width: 74%;
    }

    .fileUploader input {
      display: none;
    }
  }

  .fileUploader {
    width: 25%;
  }

  &_OptionalSettings {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &_TextArea {
    background-color: #40c1d6;
    border: none;
    outline: none;
    height: 100%;
    color: #fff;
    width: 100%;
    border-radius: 8px;
    resize: none;
    overflow: auto;
    padding: 8px;
    font-size: 14px;
  }

  &Text_Dimensions {
    display: flex;
    justify-content: space-between;

    .FormGroup {
      width: 49%;
    }
  }
}
