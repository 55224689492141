.MessageDialog {
  flex: 1;
  min-height: 0;

  &_ActionsContainer {
    margin-bottom: 22px;
  }

  &_CheckboxContainer {
    margin-top: 20px;
  }
}
