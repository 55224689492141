.ConversationCard {
  position: relative;
  min-height: 100px;
  max-height: 110px;
  transition: all 0.2s ease-in-out;
  width: 100%;

  border-color: rgb(128, 0, 0);
  border-width: 3px;
}
