.Flows {
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 15px;

  &_Header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .TitleContainer {
      margin-right: 60px;
    }

    button {
      margin-right: 26px;
    }
  }

  &_ListContainer {
    width: 100%;
    max-width: 700px;
    margin-top: 20px;
    overflow: auto;
  }

  &_NoFlowsParagraph {
    width: 271px;
    height: 96px;
    text-align: center;
    margin-top: 30px;
  }
}
