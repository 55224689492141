.ParentConnectorDialog {
  .content-view {
    flex: 1;
    min-height: 0;

    .Training {
      .Settings_Container {
        padding: 0px 25px 0px 25px;
        display: flex;
        flex-flow: column wrap;
        align-items: flex-start;
      }
    }
  }
}
