.VerticalOrderedList {
  .title {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    color: #56595f;
    margin-bottom: 14px;
  }

  .container-style {
    display: flex;
    flex-direction: row;

    .element-container-style {
      display: flex;
      flex-direction: row;
      width: 100%;
      overflow-x: auto;
      min-height: 40px;
      align-items: center;
      background-color: #eaeae8;
    }
  }
}
