#QnaTopBar {
  display: flex;
  flex-flow: row;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
  align-items: center;
  z-index: 1;
  padding: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  #SearchContainer {
    margin-left: 10px;
    flex-grow: 1;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
  }

  .ui.selection.active.dropdown {
    border-color: var(--main-blue);
  }
  .ui.selection.dropdown:focus {
    border-color: var(--main-blue);
  }
  .ui.selection.active.dropdown .menu {
    border-color: var(--main-blue);
  }

  #CreateNewPairButton {
    margin-right: 10px;
  }
}

#QnaEntriesHeader {
  display: flex;
  flex-flow: row nowrap;
  font-size: 1rem;
  font-weight: bold;
  justify-content: flex-start;
  margin-bottom: 10px;

  span {
    width: 50%;
  }
}

#QnaEntriesContainer {
  margin: 20px;
  display: flex;
  flex-flow: column nowrap;
}
