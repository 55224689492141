.CarouselComponent {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.CarouselComponent_ScrollBackContainer {
  width: 35px;
  margin-left: 4px;
  z-index: 1000000;
  position: absolute;
  left: 0;
  top: calc(50% - 17px);
}

.CarouselComponent_ScrollForwardContainer {
  width: 35px;
  margin-right: 5px;
  z-index: 1000000;
  position: absolute;
  right: 0;
  top: calc(50% - 17px);
}

.CarouselComponent_ScrollContainer {
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.CarouselComponent_ScrollContainer::-webkit-scrollbar {
  display: none;
}

.CarouselComponent_SlidesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;
  height: fit-content;
}
