.Table {
  display: flex;
  flex-direction: column;
  .table-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 20px 0 20px;
    .title-text {
      color: black;
    }
    .title-right-container {
      display: flex;
      align-items: center;
    }
    .drop-down-style {
      min-width: 120;
      margin: 10px 20px;

      .MuiOutlinedInput-root {
        height: 30px;
      }
      .MuiSelect-select:focus {
        background-color: transparent;
      }

      .MuiSelect-outlined {
        display: flex;
        align-items: center;
      }
    }
  }
  .table-generic {
    width: 100%;
    text-align: center;
    padding: 10px;

    .table-head {
      border-bottom: 1px solid #aaa;
      border-radius: 20px;
      .title-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    tbody tr {
      border-bottom: 1px solid #ddd;
    }

    th,
    td {
      padding: 8px;
    }
    .table-row {
      border-top: 1px solid gray;
    }
  }
}
