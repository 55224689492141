.ConversationTab {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid lightgrey;
  margin: 5px 0;
  padding: 5px 0 0 10px;
  cursor: pointer;

  &_Header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 5px;

    svg {
      color: lightgray;
      font-size: 28px;
      margin-right: 5px;
    }

    &_Title {
      padding-top: 4px;

      font-weight: 600;
      line-height: 18px;
    }
  }

  &_Content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(115, 115, 118, 1);
  }
}

.ConversationTab.firstConversation {
  border: none;
}

.ConversationTab:hover {
  border-left: 2px solid #334bfa;
}

.ConversationTab:active {
  border-left: 2px solid #334bfa;
  background-color: #e3e7fa;
}
