.DocumentFileMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &_Img {
    width: 16px;
    height: 16px;
  }
}
