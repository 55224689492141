.QnaEntryContainer {
  margin-bottom: 5px;

  .ui.selection.active.dropdown {
    border-color: var(--main-blue);
  }
  .ui.selection.dropdown:focus {
    border-color: var(--main-blue);
  }
  .ui.selection.active.dropdown:hover {
    border-color: var(--main-blue);
  }
  .ui.selection.active.dropdown:hover .menu {
    border-color: var(--main-blue);
  }
  .ui.selection.active.dropdown .menu {
    border-color: var(--main-blue);
  }
}

.QnaEntryHeader {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  background-color: #f2f2f2;
}

.QnaEntryDataContainer {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.QnaEntryLeft {
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.QnaEntryRight {
  width: 50%;
  position: relative;
}
