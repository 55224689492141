.TableAvatar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;

  .avatar-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;

    height: 25px !important;
    width: 25px !important;
    background-color: #bbb;
    border-radius: 50% !important;

    .sender-first-letter {
      width: 25px;
      font-size: 15px;
      letter-spacing: -0.408px;
      color: rgba(86, 89, 95, 0.4);
    }
    .sender-img {
      height: 25px;
      width: 25px;
    }
    .no-sender-img {
      height: 25px;
      width: 25px;
    }
  }
  .user-name {
    padding: 0 0 0 5px;
  }
}
