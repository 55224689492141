.AddLinkDialog {
  &ModalContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    font-size: 18px;
    top: 0;
    left: 0;
    cursor: default;
  }

  &Cover {
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.65;
  }

  &OuterContainer {
    border: 1px black;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &InnerContainer {
    margin: 20px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 600px;
  }

  &Title {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
  }
}
