.AnnotationUtterance {
  position: relative;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  font-weight: 600;
  color: #6d6868;
  &_EntitySelector {
    bottom: 35px;
    background: #fff;
    border: 1px solid var(--main-blue);
    border-radius: 0.28571429rem;
    max-height: 400px;
    overflow-y: auto;
    &_Item {
      cursor: pointer;
      padding: 0.7rem 1.4rem;
      &:hover {
        background: rgba(0, 0, 0, 0.05);
        color: rgba(0, 0, 0, 0.95);
      }
    }
  }
  &_Entity {
    background: rgb(100, 100, 100);
    color: #fff;
    padding: 4px;
    border-radius: 8px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    &_Name {
      position: absolute;
      background: rgb(233, 233, 233);
      color: rgb(100, 100, 100);
      padding: 4px;
      border-radius: 8px;
      bottom: 30px;
      cursor: default;
      width: max-content;
    }
    &_CloseIcon {
      padding-left: 4px;
    }
  }
}
