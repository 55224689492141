.MSTeamsConfigurationDialog_header {
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-right: 22px;
}

.MSTeamsConfigurationDialog {
  margin-top: 20px;
  margin-left: 30px;

  input {
    width: 500px;
    margin-bottom: 20px;
  }
}
