.InternalConversationCard {
  background: white;
  border-radius: 10px;
  margin: 0px 11px 0px 11px;

  .conversation-item-header-container {
    padding: 16px 5px 12px 5px;
    min-width: 70%;

    .conversation-item-header {
      min-height: auto;
      max-height: 36px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: baseline;

      .conversation-item-header-title {
        margin: 3px 14px 0px 0px;
        box-sizing: border-box;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 1px solid transparent;
        margin-right: 12px;

        min-width: 32px;
        font-size: 17px;

        background-clip: padding-box;
        display: inline-block;
        text-align: center;
        font-weight: 600;
        color: black;
        vertical-align: bottom;
        position: relative;
      }

      .conversation-item-header-name-container {
        display: flex;
        flex-wrap: wrap;
        max-width: 80%;
        min-height: 50px;

        .conversation-item-header-name {
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          display: block !important;

          color: #858585;
        }

        .conversation-item-header-name-unread {
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          display: block !important;

          font-weight: bold;
          color: #151515;

          .truncated-line {
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            display: block !important;
          }
        }
      }
    }

    .conversation-item-header-time {
      position: relative;
      margin-left: auto;

      line-height: 18px;

      display: flex;
      align-items: center;
      text-align: right;

      color: #c4c4c4;
    }

    .conversation-item-body-unread {
      position: relative;
      margin-top: 6px;
      display: flex;
      align-content: center;

      word-wrap: break-word;
      word-break: break-word;

      font-weight: bold;
      color: #151515;
    }

    .conversation-item-body {
      position: relative;
      margin-top: 6px;
      display: flex;
      align-content: center;

      word-wrap: break-word;
      word-break: break-word;

      color: #858585;

      span {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        display: block !important;

        .status-icon {
          color: #2185d0;
        }

        .status-icon.MuiSvgIcon-root {
          font-size: 1rem;
          margin-right: 5px;
          transform: translate(0px, 2px);
        }
      }
    }
  }
  .truncated-line {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: block !important;
  }
}

// .InternalConversationCard.active,
// .InternalConversationCard:active {
//   background: #FAFAFF;
// }

.InternalConversationCard.hover,
.InternalConversationCard:hover {
  background-color: #ededed;
}
