.DropContainer {
  .root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #333;
    outline-offset: -8px;
    outline-style: dashed;
    outline-width: 2px;
    outline-color: currentColor;
    z-index: 100;
    padding: 16px;
    font-size: 0.875rem;
    pointer-events: auto; /* otherwise ` isInside ` fails when the parent is disabled*/
  }

  .POSTURE_NORMAL {
    visibility: hidden
  }

  .POSTURE_BAD {
    color: #f44336
  }

  .POSTURE_APPROACH {
    color: #2196F3
  }

  .POSTURE_GOOD {
    background: rgba(255, 255, 255, 1);
    color: #4caf50
  }

  .isAlwaysOn {
    visibility: visible;
  }
}