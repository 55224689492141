.GenericMultiDataLine {
  .isResizable {
    padding: 10px;
    width: 100%;
    height: 800px;
    min-width: 280px;
    min-height: 280px;
    max-height: 400px;

    .chart-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column;

      .chart-title-label {
        font-weight: bold;
        color: black;

        font-size: 20px;
        margin-left: 20px;
        margin-top: 10px;
      }
      .underneath-chart-title-label {
        margin-left: 20px;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .content-value {
          margin: 5px;
          display: flex;
          align-items: flex-end;
          color: black;
        }

        .under-title-text {
          color: black;
          margin: 0 0 0 5px;
        }
      }
      .no-data {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        height: 100%;
      }
      .under-chart-label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-self: center;

        width: 90%;
        background-color: #ffffff;
        border-radius: 15px;
        border: 1px solid #e5e5e5;
        align-items: center;
        margin-top: 40px;

        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          width: 50%;
          margin: 5px;

          .content-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #a3aed0;

            .dot-mark {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              margin: 5px;
            }
          }
          .content-value {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 20px;

            font-size: 18px;
            line-height: 30px;
            letter-spacing: -2%;
            color: #2b3674;
          }
        }
      }
      .under-chart-text {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;

        margin: 5px;
        color: black;
      }
    }
  }
}
.value-change.value-green {
  color: rgba(26, 176, 87, 255);
}
.value-change.value-red {
  color: rgb(253, 110, 110);
}
.value-change {
  display: flex;
  color: rgb(253, 110, 110);
  align-items: center;
  .arrow-icon {
  }
  .arrow-icon-down {
    transform: rotate(180deg);
  }
}
