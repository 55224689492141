.HTMLCard {
  max-width: 600px;
}
.HTMLCardAction {
  padding-bottom: 16px !important;
}
.HTMLCardIframe {
  border: 0;
}
@media all and (max-width: 1000px) {
  .HTMLCard {
    max-width: 320px;
  }
  .HTMLCardIframe {
    max-width: 300px;
  }
}